import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { withPrefix } from "gatsby";
import { cdnRoot } from "../../utils/media";

const SERVING_OPTION_TO_SVG = {
  delivery: "MapDelivery.svg",
  pickup: "MapPickup.svg",
  sitdown: "MapSitdown.svg",
};

const GoogleMapComponent = ({
  google,
  height,
  locations,
  appStyles,
  servingOption,
  locationCoordsClicked,
  center: propsCenter
}) => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({...propsCenter});
  const [infoWindowToShow, setInfoWindowToShow] = useState(null);

  useEffect(() => {
    if (propsCenter.lat && propsCenter.lng) {
      setCenter(propsCenter);
    }
  }, [propsCenter]);

  const { iconPath, mapPinIcon } = appStyles;

  const iconForMapPin = mapPinIcon ? `${cdnRoot}/images/${mapPinIcon}` : withPrefix(
    `${iconPath}${SERVING_OPTION_TO_SVG[servingOption]}`
  );

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);


  useEffect(() => {
    if (_.isEmpty(center)) return;

    map?.panTo(center);
  }, [center]);

  const onMapClicked = () => {
    setInfoWindowToShow(null);
  };

  const onMarkerClick = (key) => {
    setInfoWindowToShow(key);
  };

  const onInfoWindowCloseClick = () => {
    setInfoWindowToShow(null);
  };

  return google?.isLoaded ? (
    <div style={{ height, position: "relative" }}>
      <GoogleMap
        center={center}
        zoom={17}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        onClick={onMapClicked}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {_.isEmpty(locations) && center && <Marker name={'Current Location'} position={center} icon={{
              url: iconForMapPin,
              scaledSize: new window.google.maps.Size(50, 50),
            }}/>}
        {_.map(locations, (location, key) => (
          <Marker
            key={key}
            name={location.name}
            onClick={() => onMarkerClick(key)}
            position={
              !_.isEmpty(location.geoPoint) && {
                lat: location.geoPoint.latitude,
                lng: location.geoPoint.longitude,
              }
            }
            icon={{
              url: iconForMapPin,
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          >
            {infoWindowToShow === key && (
              <InfoWindow onCloseClick={onInfoWindowCloseClick} >
                <div style={{ color: '#333'}}>{location.name}</div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
      <div id="placeDetails" />
    </div>
  ) : null;
};

export default GoogleMapComponent;
